<template>
  <div class="create pb-4" style="width: 100%">
    <CCardGroup class="has-loading-overlay">
      <CCard class="p-3">
        <div style="background-color: #f5f5f0">
          <CRow>
            <div class="d-flex header-content">
              <div class="title-header p-3" style="min-width: 150px">
                <CRow class="justify-content-center">
                  <img
                    v-if="jobReference"
                    :src="fileSource.logo"
                    class="rounded-circle ratio-1"
                    style="width: 60%"
                    @error="
                      $event.target.src = '/img/hospital.png';
                      $event.target.style = 'width:60%';
                    "
                    alt="logo"
                  />
                  <span
                    class="text-danger parent-info"
                    v-if="!isCandidateOrPublicBoard && isParentReBranding"
                    style="color: danger"
                  >
                    <em
                      class="dot"
                      v-c-tooltip="{
                        content: 'Parent Branding Enabled',
                        appendToBody: true,
                      }"
                    ></em>
                  </span>
                </CRow>
                <div class="my-3">
                  <h5 class="text-primary text-center font-weight-bold">
                    {{ organisation }}
                  </h5>
                  <h6 class="label lblspan text-primary text-center">
                    <em class="fas fa-map-marker-alt"></em>&nbsp;
                    <span style="color: #00000099">{{ location }}{{ displayLocation ? ' | ' + displayLocation : '' }}</span>
                  </h6>
                </div>
              </div>
              <div class="p-4" style="min-width: 150px">
                <div class="title row">
                  <h4 class="text-left text-primary">{{ title }}</h4>
                </div>
                <CRow class="d-flex justify-content-between flex-row">
                  <CCol class="property">
                    <p class="text-primary">Candidate Type</p>
                    <p class="value">{{ candidateType }}</p>
                    <div
                      v-if="isNurse && jobDetails.location.country_id == 182"
                    >
                      <p class="value">{{ nhsBand }}</p>
                    </div>
                  </CCol>
                  <CCol class="property">
                    <p class="text-primary">Job Reference</p>
                    <p class="value">{{ displayJobReference }}</p>
                  </CCol>
                  <CCol class="property">
                    <p class="text-primary">Date Posted</p>
                    <p class="value">{{ date_created }}</p>
                  </CCol>
                  <CCol class="property">
                    <p class="text-primary">Candidate Level</p>
                    <p
                      class="value"
                      v-if="isWrapped(candidateLevel)"
                      v-c-tooltip="{
                        content: candidateLevel,
                      }"
                    >
                      {{ candidateLevel.substring(0, 50) }}...
                    </p>
                    <p class="value" v-else>{{ candidateLevel }}</p>
                  </CCol>
                  <CCol class="property">
                    <p class="text-primary">Contract</p>
                    <p class="value">{{ contract }}</p>
                  </CCol>
                  <CCol class="property">
                    <p class="text-primary">Expires On</p>
                    <p class="value">
                      {{ expiryDate }} |
                      <span style="font-weight: 500">{{ expiryDays }}</span>
                    </p>
                  </CCol>
                </CRow>
              </div>
            </div>
          </CRow>
        </div>
        <div>
          <img
            v-if="jobReference"
            :src="fileSource.image"
            style="width: 100% !important; max-height: 250px"
            @error="$event.target.src = '/img/job-image.jpg'"
            alt="Job Image"
          />
        </div>
        <CCardBody class="p-1 mt-3 overflow-auto" v-html="jobDescription">
        </CCardBody>
        <div
          class="pl-1 pr-3"
          v-for="(item, index) in jobDocuments"
          :key="index"
        >
          For Additional Information
          <a @click="fileDownload(item)" class="cursor-pointer text-primary">
            {{ item.library_name || "Click here" }}
          </a>
        </div>
      </CCard>
    </CCardGroup>
  </div>
</template>

<script>
import m from "moment";
import { mapActions, mapGetters } from "vuex";
import { appendBaseUrlWithLink } from "@/helpers/helper";

export default {
  props: {
    jobDetails: {},
    advertDocuments: {
      type: Array,
      default: () => null,
    },
  },
  data() {
    return {
      appendBaseUrlWithLink
    }
  },

  computed: {
    ...mapGetters([
      "getOrganisationDocumentFile",
      "isCandidateSupplierFromAccessToken",
      "getRoleScopes",
    ]),
    title() {
      return this.jobDetails?.job_title || "--";
    },
    organisation() {
      if (this.isParentReBranding || this.isCandidateSupplierFromAccessToken) {
        if (
          this.jobDetails?.parent_org_info &&
          this.jobDetails?.parent_org_info?.length
        ) {
          return this.jobDetails?.parent_org_info[0]?.name || "--";
        }
      } else return this.jobDetails?.organisation?.name || "--";
    },
    candidateType() {
      return this.jobDetails?.candidate_type?.candidate_type || "--";
    },
    candidateLevel() {
      return this.jobDetails?.level_names?.join(", ") || "--";
    },
    jobDescription() {
      return this.jobDetails?.advert_description || "";
    },
    jobPicture() {
      return this.jobDetails?.job_picture?.link_to_doc || "";
    },
    location() {
      return this.jobDetails?.location?.location || "--";
    },
    displayLocation() {
      return this.jobDetails?.display_location;
    },
    jobReference() {
      return this.jobDetails?.job_id;
    },
    displayJobReference() {
      return this.jobDetails?.display_uid;
    },
    contract() {
      if (this.jobDetails) {
        const { permanent_work, temporary_work } = this.jobDetails;
        if (permanent_work && temporary_work) return "Permanent;Temporary";
        else if (permanent_work) return "Permanent";
        else if (temporary_work) return "Temporary";
      }
      return "--";
    },
    date_created() {
      return this.formatDate(this.jobDetails?.created_on);
    },
    expiryDate() {
      return this.formatDate(this.jobDetails?.expiry_review_date);
    },
    expiryDays() {
      let days = 0;
      if (this.jobDetails.expiry_review_date) {
        let diff = m(this.jobDetails.expiry_review_date).diff(m(), "days");
        days = diff < 0 ? "Expired" : `${diff} Days`;
      }
      return days;
    },
    isParentReBranding() {
      return this.jobDetails?.parent_org_info_required || false;
    },
    isCandidateOrPublicBoard() {
      return (
        !this.getRoleScopes.length ||
        ["system_candidate", "customer_candidate"].includes(
          this.getRoleScopes[0]
        )
      );
    },
    isNurse() {
      return this.jobDetails?.candidate_type_id == 2 ||
        this.jobDetails?.candidate_type?.candidate_type_id == 2
        ? true
        : false;
    },
    nhsBand() {
      let data = [];
      let band = this.jobDetails?.current_nhs_bands
        ? this.jobDetails?.current_nhs_bands
        : [];
      for (var i = 0; i < band.length; i++) {
        data.push(band[i].band_name);
      }
      return data.join("/ ") || null;
    },
    fileSource() {
      let job = this.jobDetails;
      if (this.isParentReBranding || this.isCandidateSupplierFromAccessToken) {
        if (job?.parent_org_info?.length) {
          return {
            logo: appendBaseUrlWithLink(job?.parent_org_info[0]?.logo_url) || "",
            image: appendBaseUrlWithLink(job?.parent_org_info[0]?.default_job_banner_url) || "",
          };
        }
        return {
          logo: "",
          image: "",
        };
      } else {
        return {
          logo: appendBaseUrlWithLink(job?.organisation?.logo_url) || "",
          image: this.jobPicture
            ? appendBaseUrlWithLink(this.jobPicture)
            : appendBaseUrlWithLink(job?.organisation?.default_job_banner_url) || "",
        };
      }
    },
    jobDocuments() {
      let documents = this.jobDetails?.advert_documents;
      if (this.advertDocuments) {
        documents = this.advertDocuments;
      }
      if (documents?.length) {
        return documents?.map((val) => ({
          document_name:
            val?.document_name ||
            val?.document_library?.organisation_document?.document_name,
          url:
            val?.link_to_doc ||
            val?.document_library?.organisation_document?.link_to_doc,
          library_name: val?.document_library?.library_name,
        }));
      }
      return false;
    },
  },
  methods: {
    ...mapActions(["downloadDocument"]),
    formatDate(date) {
      return date ? m(date).format("DD MMM YYYY") : "--";
    },
    isWrapped(data) {
      return data?.length > 50 || false;
    },
    fileDownload(data) {
      let { url, document_name } = data;
      url = url.replace("/api/v1/", "");
      this.downloadDocument({ url, document_name });
    },
  },
};
</script>

<style lang="scss" scoped>
.property {
  font-size: 17px !important;
  font-weight: bold !important;
}
.value {
  font-size: 15px !important;
  font-weight: 400 !important;
}
.title-header {
  max-width: 25%;
}
.org-logo {
  height: 40%;
  width: 40%;
}
@media all and (max-width: 991px) {
  .property {
    min-width: 135px;
  }
  .title {
    margin-left: 0px;
  }
}
@media all and (min-width: 992px) {
  .property {
    padding: 0px;
    min-width: 33%;
  }
}
@media all and (max-width: 506px) {
  .title-header {
    max-width: 100%;
  }
  .org-logo {
    height: 25%;
    width: 25%;
  }
  .header-content {
    flex-direction: column;
  }
}
@media all and (max-width: 356px) {
  .property {
    text-align: center;
  }
}
.ratio-1 {
  aspect-ratio: 1;
}
.parent-info {
  position: absolute;
  margin-left: 5.8rem;
  margin-top: 1.05rem;
}
.dot {
  height: 10px;
  width: 10px;
  background-color: $font-theme-color;
  border-radius: 60%;
  display: inline-block;
  position: absolute;
  z-index: 1000;
  top: -5px;
  right: -5px;
}
</style>
